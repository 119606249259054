<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_causale_prima_nota"
                        :name="beForm[rep].id_causale_prima_nota.label"
                        :label="beForm[rep].id_causale_prima_nota.label"
                        v-model="form[rep].id_causale_prima_nota"
                        :options="cash_references_opt"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        @input="onCashReferenceInput"
                        :rules="getRules('id_causale_prima_nota')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="cash_entry_type"
                        label="Tipo Movimento"
                        v-model="form[rep].cash_entry_type"
                        maxlength="256"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_conto_prima_nota"
                        :name="beForm[rep].id_conto_prima_nota.label"
                        :label="beForm[rep].id_conto_prima_nota.label"
                        v-model="form[rep].id_conto_prima_nota"
                        :options="beForm[rep].id_conto_prima_nota.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('id_conto_prima_nota')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="data_registrazione"
                        :name="beForm[rep].data_registrazione.label"
                        :label="beForm[rep].data_registrazione.label"
                        v-model="data_registrazione"
                        :rules="getRules('data_registrazione')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_produttore"
                        :name="beForm[rep].id_produttore.label"
                        label="Produttore"
                        v-model="form[rep].id_produttore"
                        :options="getSalesmen()"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="has_broker"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_compagnia"
                        :name="beForm[rep].id_compagnia.label"
                        label="Compagnia"
                        v-model="form[rep].id_compagnia"
                        :options="getInsurers()"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="has_company"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="descrizione"
                        :name="beForm[rep].descrizione.label"
                        label="Descrizione"
                        v-model="form[rep].descrizione"
                        placeholder="Inserisci una descrizione"
                        maxlength="256"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="importo"
                        vid="importo"
                        label="Importo"
                        v-model="form[rep].importo"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="{
                          required: true,
                          compare_number: { otherValue: '0', operator: '>' },
                        }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import CustomInputs from "@/components/form/CustomInputs";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import { today } from "@/utils/dates";
import { toLocaleDate } from "@/utils/dates";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "cash_entry",
      args: { verb: { name: "inserisciSospeso", alias: "store" } },
      cash_references_opt: [],
      DEFERRED_CASH_REFERENCE: 2,
      has_broker: {},
      has_company: {},
      xxx_rule: { required: true },
      data_registrazione: today(),
      form: {
        cash_entry: {
          id_produttore: null,
          cash_entry_id: null,
          cash_entry_type: null,
          id_causale_prima_nota: null,
          data_registrazione: null,
          importo: 0.0,
          descrizione: null,
          id_conto_prima_nota: null,
        },
      },
    };
  },
  components: {
    CustomInputs,
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseCurrency,
    BaseDatepicker,
  },
  methods: {
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getTreasuries: "allTreasuries",
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].data_registrazione = toLocaleDate(
        this.data_registrazione,
        "YYYY-MM-DD"
      );

      const Repo = RepositoryFactory.get(this.repository);

      Repo.inserisciSospeso(this.form.cash_entry)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Sospeso Creato`,
          });
          this.shortcut(
            "cash_entries.index0",
            null,
            "#Deferred",
            "filterCashEntriesDeferred"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onCashReferenceInput(value) {
      if (!value) {
        this.xxx_rule = { required: true };
        return;
      }

      const found = this.cash_references_opt.find(
        (cash_reference) => cash_reference.value === value
      );

      if (!found) {
        return;
      }

      this.form.cash_entry.id_conto_prima_nota = found.treasury_id;
      this.form.cash_entry.cash_entry_type =
        found.entry_type == 1 ? "Uscita" : "Entrata";
      this.has_broker = found.has_broker === 1 ? { required: true } : {};
      this.has_company = found.has_company === 1 ? { required: true } : {};
      console.log(found.has_company);
      const ENTRY_IN_RULE = {
        required: true,
        compare_number: { otherValue: "0", operator: ">" },
      };
      const ENTRY_OUT_RULE = {
        required: true,
        compare_number: { otherValue: "0", operator: "<" },
      };
      this.xxx_rule = found.entry_type === 0 ? ENTRY_IN_RULE : ENTRY_OUT_RULE;
    },
    fetchCreateForm(repository) {
      const Repo = RepositoryFactory.get("cash_entry");
      return Repo.creaSospeso()
        .then((response) => {
          const data = response.data;
          this.version = data.version;
          if (this.extendedBeForm && Object.keys(this.extendedBeForm).length) {
            this.beForm[repository] = {
              ...data.fields,
              ...(this.extendedBeForm || {}),
            };
          } else {
            this.beForm[repository] = data.fields;
          }
          this.beRules[repository] = data.rules;
          this.ok = true;
        })
        .catch((error) => {
          this.ok = false;
          this.errorMessage = error;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.cash_references_opt = [];
      let cashReferences = this.getCashReferences();
      cashReferences.sort((a, b) => a.text - b.text);
      cashReferences.forEach((el) => {
        this.cash_references_opt.push({
          text: `${el.text} - ${el.description}`,
          reference_type: el.reference_type,
          has_broker: el.has_broker,
          has_company: el.has_company,
          entry_type: el.entry_type,
          value: el.value,
          treasury_id: el.treasury_id,
        });
      });
      this.cash_references_opt = this.cash_references_opt.filter(
        (cash_reference) =>
          cash_reference.reference_type == this.DEFERRED_CASH_REFERENCE
      );
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
