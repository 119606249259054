var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "editDeferredCashEntryModal",
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: function ($event) {
          return _vm.close()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "pencil" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Modifica dettaglio")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel(), _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("validation-observer", {
            ref: "observer1",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit, invalid }) {
                  return [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "b-card",
                          { staticClass: "filter" },
                          [
                            _c("b-row", [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-datepicker", {
                                    attrs: {
                                      vid: "valuta_da",
                                      name: "Data Recupero Sospeso",
                                      label: "Data Recupero Sospeso",
                                      disabled: true,
                                      readonly: true,
                                      rules: { required: true },
                                    },
                                    model: {
                                      value: _vm.inputVal.data_registrazione,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputVal,
                                          "data_registrazione",
                                          $$v
                                        )
                                      },
                                      expression: "inputVal.data_registrazione",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      vid: "broker_id",
                                      name: "produttore",
                                      label: "Produttore",
                                      options: _vm.getSalesmen(),
                                      taggable: false,
                                      multiple: false,
                                      closeOnSelect: true,
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.inputVal.id_produttore,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputVal,
                                          "id_produttore",
                                          $$v
                                        )
                                      },
                                      expression: "inputVal.id_produttore",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      vid: "cash_reference_id",
                                      name: "causale",
                                      label: "Causale",
                                      options: _vm.cash_references_opt,
                                      taggable: false,
                                      multiple: false,
                                      closeOnSelect: true,
                                      disabled: true,
                                      rules: { required: true },
                                    },
                                    model: {
                                      value: _vm.inputVal.id_causale_prima_nota,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputVal,
                                          "id_causale_prima_nota",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inputVal.id_causale_prima_nota",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      vid: "title",
                                      name: "descrizione",
                                      label: "Descrizione",
                                      placeholder: "Inserisci una descrizione",
                                      maxlength: "256",
                                      rules: { required: true },
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.inputVal.related_description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputVal,
                                          "related_description",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inputVal.related_description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-currency", {
                                    attrs: {
                                      name: "importo",
                                      vid: "gross",
                                      label: "Importo",
                                      options: {
                                        currency: "EUR",
                                        locale: "it-IT",
                                        precision: 2,
                                      },
                                      rules: {
                                        required: true,
                                        compare_number: {
                                          otherValue: "0",
                                          operator: ">",
                                        },
                                      },
                                    },
                                    model: {
                                      value: _vm.inputVal.gross,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputVal, "gross", $$v)
                                      },
                                      expression: "inputVal.gross",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      vid: "treasury_id",
                                      name: "conto",
                                      label: "Conto",
                                      options: _vm.getContiPrimaNota(),
                                      taggable: false,
                                      multiple: false,
                                      closeOnSelect: true,
                                      rules: { required: true },
                                    },
                                    model: {
                                      value: _vm.inputVal.id_conto_prima_nota,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputVal,
                                          "id_conto_prima_nota",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inputVal.id_conto_prima_nota",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "py-2 mb-4" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  type: "button",
                                  disabled: invalid,
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return handleSubmit(_vm.save)
                                  },
                                },
                              },
                              [_vm._v("\n            Salva\n          ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }