var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.canVerb(_vm.resource, "index")
      ? _c(
          "div",
          { staticClass: "mx-1" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { align: "left" } },
                  [
                    _c(
                      "b-button-group",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle:collapse-1",
                            arg: "collapse-1",
                          },
                        ],
                        staticClass: "my-2 filter-button-group",
                        attrs: { title: _vm.getDefaultFilterMessage() },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "when-open" },
                          [
                            _c("b-icon", {
                              attrs: { icon: "funnel", "font-scale": "1.5" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "when-closed" },
                          [
                            _c("b-icon", {
                              attrs: { icon: "funnel", "font-scale": "1.5" },
                            }),
                          ],
                          1
                        ),
                        _vm._v("\n          Filtra\n        "),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              { attrs: { visible: "", id: "collapse-1" } },
              [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSearch()
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "filter" },
                      [
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  vid: "conto",
                                  name: "conto",
                                  label: "Conto",
                                  options: _vm.treasuries_opt,
                                  taggable: false,
                                  multiple: false,
                                  closeOnSelect: true,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onInputTreasury()
                                  },
                                },
                                model: {
                                  value: _vm.filter.conto,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "conto", $$v)
                                  },
                                  expression: "filter.conto",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-datepicker", {
                                attrs: {
                                  name: "cash_time",
                                  label: "Saldo conto al",
                                },
                                model: {
                                  value: _vm.filter.fino_al,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "fino_al", $$v)
                                  },
                                  expression: "filter.fino_al",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group col-md-3 align-self-end",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    variant: "lisaweb",
                                    size: "sm",
                                  },
                                },
                                [_vm._v("Calcola")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-reset",
                                  attrs: {
                                    type: "button",
                                    variant: "lisaweb",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClearFilter()
                                    },
                                  },
                                },
                                [_vm._v("Reset")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-overlay",
                      {
                        attrs: {
                          center: "",
                          show: _vm.isLoading,
                          rounded: "sm",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "overlay",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      class: `text-center${
                                        _vm.search ? " mt-3" : " mt-9"
                                      }`,
                                    },
                                    [
                                      _c("base-icon", {
                                        attrs: {
                                          name: "loading",
                                          width: "35",
                                          height: "35",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { attrs: { id: "cancel-label" } },
                                        [_vm._v("Operazione in corso...")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          801721212
                        ),
                      },
                      [
                        _vm.filter.conto && _vm.search
                          ? _c("b-card", { staticClass: "filter mt-2" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("div", { class: _vm.status }, [
                                  _c("h1", { staticClass: "total" }, [
                                    _vm._v(
                                      "\n                  Il saldo del conto\n                  " +
                                        _vm._s(_vm.treasury) +
                                        "\n                  è :\n                  " +
                                        _vm._s(_vm.toLocaleCurrency(_vm.data)) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "mt-3" },
          [
            _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
            _vm._v("\n    Non hai i permessi\n  "),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }