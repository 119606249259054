var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "lg",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { ok: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "archive-fill" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Chiusura Massiva")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel()
                      _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("validation-observer", {
            ref: "observer1",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit, invalid }) {
                  return [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "b-card",
                          { staticClass: "filter" },
                          [
                            _c("b-row", [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-datepicker", {
                                    attrs: {
                                      vid: "book_date",
                                      name: "data_registrazione",
                                      label: "Data Registrazione",
                                      min: _vm.inputDate,
                                      rules: { required: true },
                                    },
                                    model: {
                                      value: _vm.form.cash_time,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "cash_time", $$v)
                                      },
                                      expression: "form.cash_time",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      name: "id_conto_prima_nota",
                                      vid: "treasury_id",
                                      label: "Conto",
                                      options: _vm.treasuries_opt,
                                      rules: { required: true },
                                    },
                                    model: {
                                      value: _vm.form.treasury_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "treasury_id", $$v)
                                      },
                                      expression: "form.treasury_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("b-row", [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      vid: "cash_reference_id",
                                      name: "id_causale_prima_nota",
                                      label: "Causale",
                                      options: _vm.cash_references_opt,
                                      taggable: false,
                                      multiple: false,
                                      closeOnSelect: true,
                                      rules: { required: true },
                                      preselectFirst: true,
                                    },
                                    model: {
                                      value: _vm.form.cash_reference_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "cash_reference_id",
                                          $$v
                                        )
                                      },
                                      expression: "form.cash_reference_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-currency", {
                                    attrs: {
                                      name: "importo",
                                      vid: "total_mass_deferred",
                                      label: "Importo Totale",
                                      options: {
                                        currency: "EUR",
                                        locale: "it-IT",
                                        precision: 2,
                                      },
                                      readonly: "",
                                    },
                                    model: {
                                      value: _vm.inputVal,
                                      callback: function ($$v) {
                                        _vm.inputVal = $$v
                                      },
                                      expression: "inputVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-2 mb-4 float-right",
                            attrs: {
                              type: "button",
                              disabled: invalid,
                              variant: "outline-lisaweb",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return handleSubmit(_vm.save)
                              },
                            },
                          },
                          [_vm._v("\n          Salva\n        ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }