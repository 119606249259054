<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      data-type="id_causale_prima_nota"
                    >
                      <base-select
                        vid="id_causale_prima_nota"
                        :name="beForm[rep].id_causale_prima_nota.label"
                        :label="beForm[rep].id_causale_prima_nota.label"
                        v-model="form[rep].id_causale_prima_nota"
                        :options="cash_references_opt"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        @input="onCashReferenceInput"
                        :rules="getRules('id_causale_prima_nota')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="cash_entry_type"
                        label="Tipo Movimento"
                        v-model="form[rep].cash_entry_type"
                        maxlength="256"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_conto_prima_nota"
                        :name="beForm[rep].id_conto_prima_nota.label"
                        :label="beForm[rep].id_conto_prima_nota.label"
                        v-model="form[rep].id_conto_prima_nota"
                        :options="beForm[rep].id_conto_prima_nota.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('id_conto_prima_nota')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="data_registrazione"
                        :name="beForm[rep].data_registrazione.label"
                        :label="beForm[rep].data_registrazione.label"
                        v-model="form[rep].data_registrazione"
                        :rules="getRules('data_registrazione')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_compagnia"
                        :name="beForm[rep].id_compagnia.label"
                        label="Compagnia"
                        v-model="form[rep].id_compagnia"
                        :options="companies"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="has_company"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_produttore"
                        :name="beForm[rep].id_produttore.label"
                        label="Produttore"
                        v-model="form[rep].id_produttore"
                        :options="getSalesmen()"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="has_broker"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="descrizione"
                        :name="beForm[rep].descrizione.label"
                        label="Descrizione"
                        v-model="form[rep].descrizione"
                        placeholder="Inserisci una descrizione"
                        maxlength="256"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="importo"
                        vid="importo"
                        label="Importo"
                        v-model="form[rep].importo"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import CustomInputs from "@/components/form/CustomInputs";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import { today } from "@/utils/dates";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [FormMixin, ShortcutMixin, StorageGetterMixin],
  data() {
    return {
      repository: "cash_entry",
      cash_references_opt: [],
      companies: [],
      STANDARD_CASH_REFERENCE: 0,
      has_broker: {},
      has_company: {},
      importo_rule: { required: true },
      form: {
        cash_entry: {
          id_produttore: null,
          cash_entry_id: null,
          cash_entry_type: null,
          id_causale_prima_nota: null,
          data_registrazione: today(),
          importo: 0.0,
          descrizione: null,
          id_conto_prima_nota: null,
        },
      },
    };
  },
  components: {
    CustomInputs,
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseCurrency,
    BaseDatepicker,
  },
  methods: {
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getTreasuries: "allTreasuries",
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
    // fetchCreateForm(repository) {
    //   const Repo = RepositoryFactory.get('cash_entry');
    //   return Repo.creaMovimentoVarios()
    // },
    onCashReferenceInput(value) {
      if (!value) {
        this.importo_rule = { required: true };
        return;
      }

      const found = this.cash_references_opt.find(
        (cash_reference) => cash_reference.value === value
      );

      if (!found) {
        return;
      }

      this.form.cash_entry.id_conto_prima_nota = found.treasury_id;
      this.form.cash_entry.cash_entry_type =
        found.entry_type == 1 ? "Uscita" : "Entrata";
      this.has_company = found.has_company === 1 ? { required: true } : {};
      const ENTRY_IN_RULE = {
        required: true,
        compare_number: { otherValue: "0", operator: ">" },
      };
      const ENTRY_OUT_RULE = {
        required: true,
        compare_number: { otherValue: "0", operator: "<" },
      };

      this.importo_rule =
        found.entry_type === 0 ? ENTRY_IN_RULE : ENTRY_OUT_RULE;
    },
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].data_registrazione = toLocaleDate(
        this.form[this.rep].data_registrazione,
        "YYYY-MM-DD"
      );
      this.store(this.repository)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Movimento Contabile Creato`,
          });
          this.shortcut(
            "cash_entries.index0",
            null,
            "#BookEntries",
            "filterCashEntriesDefault"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getCurrentCashReferences(value) {
      const cashReferences = this.getCashReferences();
      const found = cashReferences.find(
        (cash_reference) => cash_reference.value == value
      );
      return `${found?.text} - ${found?.description}`;
    },
  },
  created() {
    this.isLoading = true;
    /* */
    console.log("created index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {
            console.info(`background data "${path}insurers" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.companies = this.getInsurers();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.companies = this.getInsurers();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        });
    }
    /* */
    this.fetchCreateForm(this.repository).then(() => {
      this.cash_references_opt = [];
      let cashReferences = this.getCashReferences();
      cashReferences.sort((a, b) => a.text - b.text);
      cashReferences.forEach((el) => {
        this.cash_references_opt.push({
          id_causale_prima_nota: el.id_causale_prima_nota,
          text: `${el.text} - ${el.description}${
            el.linked_cash_reference
              ? `
↪ ${this.getCurrentCashReferences(el.linked_cash_reference)} `
              : ""
          }`,
          reference_type: el.reference_type,
          has_broker: el.has_broker,
          has_company: el.has_company,
          entry_type: el.entry_type,
          value: el.value,
          treasury_id: el.treasury_id,
        });
      });
      this.cash_references_opt = this.cash_references_opt.filter(
        (cash_reference) =>
          cash_reference.reference_type == this.STANDARD_CASH_REFERENCE
      );
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    getFullCashReferences() {
      let fullCashReferences = [];
      let cashReferences = this.getCashReferences();
      cashReferences.sort((a, b) => a.text - b.text);
      cashReferences.forEach((el) => {
        fullCashReferences.push({
          text: `${el.text} - ${el.description}`,
          value: el.value,
        });
      });
      return fullCashReferences;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(
    div[data-type="id_causale_prima_nota"]
      .multiselect
      .multiselect__option
      span
  ) {
  white-space: pre-line;
}
:deep(
    div[data-type="id_causale_prima_nota"] .multiselect .multiselect__single
  ) {
  white-space: pre-line;
}
</style>
